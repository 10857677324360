@import "mixins.scss";

.topbar {
    background-color: var(--white);
    color: var(--text-color);
    border-top: 1px solid #f1f4f6;
    border-bottom: 1px solid #f1f4f6;
    margin: 0px;
    display: none;

    @include sm {
        display: block;
    }
}


.wrapper-top-bar {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 5px 0px;

    .leftTopBar {
        .displayNumber {
            margin-left: 5px;
        }
    }

    .rightTopBar {
        float: right;

        .seperator {
            margin: 5px;
        }
    }
}

.headerLogo {
    width: 15%;
}

.userLogin {
    cursor: pointer;
}

.headerCart {
    width: 15%;
    display: flex;
    justify-content: end;

    .topCartCounter {
        .cartIcons {
            border: 1px solid var(--orange);
            border-radius: 54px;
            color: var(--black);
            font-size: 43px;
            margin-top: 3px;
            padding: 8px;
            cursor: pointer;
        }

        .cartCounter {
            background: var(--orange);
            border-radius: 15px;
            color: #fff;
            font-size: 19px;
            margin-left: -7px;
            position: absolute;
            text-align: center;
            width: 30px;
        }
    }

    .yourCart {
        font-size: 10px;
        font-weight: 600;
        margin-left: 35px;
        margin-top: 10px;

        .textCart {
            color: var(--orange);
        }

        .totalPriceCart {
            color: var(--orange);
        }
    }
}

.headerSearch {
    width: 70%;
    margin: auto;

    form {
        align-items: center;
        display: flex;
        width: 100%;
        gap: 2px;
    }

    input {
        background-color: var(--white);
        border: none;
        border-radius: 0 6px 6px 0;
        bottom: 19px;
        box-shadow: none;
        color: #3e3e3e;
        display: block;
        display: inline;
        font-size: 14px;
        height: 40px;
        line-height: 21px;
        outline: 0;
        padding: 6px 30px 7px 0;
        text-indent: 12px;
        width: calc(100% - 25px);
    }

    .locationBox {
        background-color: var(--white);
        display: flex;
        width: 30%;
        padding: 0px 5px 0px 5px;

        svg {
            margin: auto;
        }

        .btnlocation {
            cursor: pointer;

            svg {
                margin: auto;
                margin-top: 12px;
            }
        }
    }

    .searchBox {
        background-color: var(--white);
        display: flex;
        width: 70%;

        svg {
            margin: auto;
            margin-top: 10px !important;
        }
    }
}

.breadcrumb {
    letter-spacing: 1px;
}

.breadcrumbs {
    margin-top: 5px;
    margin-bottom: unset !important;
}

.aboutbody {
    p {
        letter-spacing: 0.8px;
    }

    ul {
        margin-left: 45px !important;
    }
}

.navbarsection {
    display: none;

    @include sm {
        display: block;
    }

    .desktopnavbar {
        width: 100%;
        display: flex !important;
        justify-content: space-between;

    }

    a {
        color: var(--black) !important;
        letter-spacing: 1px;
        font-weight: 600;
    }
}

.videosection {
    margin-top: 10px;

    .react-multiple-carousel__arrow--left {
        left: 5px;
    }

    .react-multiple-carousel__arrow--right {
        right: 5px;
    }
}

.bestSellerSection {
    padding: 0px 5px !important;

    @include md {
        padding: unset !important;
    }

    ul {
        gap: 5px;

        li {
            width: 325px !important;
        }
    }
}

.bodySection {
    padding: 5px 0px 5px 0px;
}

// Product
.descHeader {
    h2 {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: .6px !important;
        text-align: left !important;
        padding: 10px 6px !important;

        @include md {
            text-align: center !important;
            padding: 0px 10px;
        }
    }

    p {
        color: dimgray !important;
        font-family: Inter, sans-serif !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400 !important;
        letter-spacing: .6px !important;
        line-height: 1.74 !important;
        visibility: visible;
        text-align: left !important;
        padding: 10px 6px !important;

        @include md {
            text-align: center !important;
            padding: 0px 10px;
        }
    }
}

.bestseller-body {
    .card-text {
        .offersection {
            display: flex;
            justify-content: space-between;

            .priceSection {
                display: flex;
                gap: 20px;

                .price {
                    font-size: 20px;
                    color: var(--orange);
                    letter-spacing: .6px !important;
                }

                .cutprice {
                    font-size: 17px;
                    margin-top: 5px;
                    letter-spacing: .6px !important;
                }
            }

            .offer {
                span {
                    color: var(--light-green);
                }
            }
        }


        .ratingSection {
            display: flex;
            justify-content: space-between;

            .rating {
                color: var(--light-green);
            }
        }
    }

    .buttonSection {
        display: flex;
        justify-content: space-between;

        .addtocart {
            width: 48%;
            background-color: var(--white);
            color: var(--black);
            text-transform: uppercase;
            border: 1px solid var(--black);
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--black);
                color: var(--white);
            }
        }

        .buyitnow {
            width: 48%;
            background-color: var(--orange);
            color: var(--white);
            text-transform: uppercase;
            border: none;
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--white);
                color: var(--black);
                border: 1px solid var(--black);
            }
        }
    }
}

.shopByConcernsection {
    ul {
        display: flex;
        gap: 10px;

        li {
            width: 211px !important;
            height: 211px !important;

            img {
                width: 100% !important;
            }
        }
    }
}

.cirtification row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.cirtificationbody {
    background: #f8f9fa !important;
    text-align: center;
    border-radius: 10px;
    padding: 10px;

    img {
        width: 50%;
    }

    h3 {
        font-size: 1rem;
    }

    p {
        font-size: 14px !important;
    }
}

.consultant {
    background-color: var(--white);
    padding: 10px 0px 10px 0px;

    .drdesc h3 {
        color: var(--orange);
        font-size: 25px !important;
        font-weight: 600;
        margin-top: 10px;
    }

    .drdesc .talkdrdesc {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
    }

    .drdesc .btntalk {
        background: var(--orange);
        border: none;
        border-radius: 5px;
        color: var(--white);
        font-size: 15px;
        margin: auto;
        padding: 6px 10px 6px 10px;
        text-transform: uppercase;
    }

    .image {
        img {
            width: 100%;
        }
    }

    .heading {
        margin-bottom: 20px;
    }
}

.bestseller-body {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .85), 1px 2px 4px 0px rgb(194 186 201 / 95%);
    border-radius: 5px;
    margin: 0px 5px 0px 5px;

    .card {
        border: none;
    }
}

.shopByConcern {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .85), 1px 2px 4px 0px rgb(194 186 201 / 95%);
    border-radius: 10px;
    margin: 0px 5px 0px 5px;
}

.homereating {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .85), 1px 2px 4px 0px rgb(194 186 201 / 95%);
    font-family: Inter, sans-serif;
    letter-spacing: .3px;
    line-height: 1.8;
    border: none;
    margin: 0px 5px 0px 5px;

    .namerating {
        margin-bottom: 10px;

        .rating {
            background: #388e3c;
            border-radius: 5px;
            color: #fff;
            float: left;
            font-size: 14px;
            margin-right: 10px;
            padding: 2px 0px 2px 5px;
        }

        .user-name {
            display: flex;
            justify-content: space-between;

            h6 {
                color: var(--black);
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin-top: 5px;
                padding: 0;
            }
        }
    }

    .postdate {
        display: flex;
        justify-content: space-between;

        .duration {
            color: var(--orange);
        }

        .location {
            h6 {
                color: var(--black);
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                margin-top: 5px;
                padding: 0;
            }
        }
    }

    p {
        color: dimgray !important;
        font-family: Inter, sans-serif !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400 !important;
        letter-spacing: 0.8px !important;
        line-height: 1.74 !important;
        visibility: visible;
    }

}

.homeblog {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .85), 1px 2px 4px 0px rgb(194 186 201 / 95%);
    font-family: Inter, sans-serif;
    letter-spacing: .3px;
    line-height: 1.8;
    border: none;
    margin: 0px 5px 0px 5px;

    .card-title {
        color: var(--black);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: .6px;
    }

    p {
        color: dimgray !important;
        font-family: Inter, sans-serif !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400 !important;
        letter-spacing: 0.6px !important;
        line-height: 1.74 !important;
        visibility: visible;
    }
}

.footer {
    .footerNewsletter {
        p {
            color: var(--white);
            font-family: Inter, sans-serif !important;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400 !important;
            letter-spacing: 1px !important;
            line-height: 1.74 !important;
            visibility: visible;
        }

        input {
            border: 1px solid var(--text-gray);
            border-radius: 50px 0 0 50px;
            padding: 6px 0 9px 16px;
            width: 70%;
        }

        button {
            background: var(--orange);
            border: none;
            border-radius: 0 50px 50px 0;
            color: #fff;
            padding: 7px 2px 10px 0;
            width: 30%;
        }
    }

    h4 {
        color: var(--white);
        font-size: 15px !important;
        font-weight: 600;
        padding-bottom: 5px;
        position: relative;
        text-transform: uppercase;

        &:before {
            background: var(--orange);
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            width: 80px;
        }
    }

    .userfulllink {
        margin-bottom: unset !important;

        li {
            list-style: none;
            font-size: 14px;
            margin-top: 5px;

            svg {
                font-size: 20px;
                color: var(--orange);
                margin-right: 5px;
            }

            a {
                color: var(--white);
                letter-spacing: 1px;

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }

    .socialmedialink {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        li {
            list-style: none;

            a {
                color: var(--white);

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }
}

.footerBottom {
    background-color: #23242e;
    color: #fff;
    padding: 10px 0px 10px 0px;

    address {
        margin-bottom: unset;
    }

    img {
        float: right;
    }
}

.topmarquee {
    white-space: nowrap;
    overflow: hidden;
    background-color: none;
    width: 100%;
    color: #000;
    text-transform: uppercase;

    marquee {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        background-color: var(--background-color);
        padding: 5px;

        .marqueeBody {
            -webkit-animation: marquee 15s linear infinite;
            animation: marquee 15s linear infinite;

            .text {
                display: inline-block;
                margin-right: 5.3em;
                font-size: 14px;
                line-height: 2.4;
                position: relative;
                letter-spacing: .6px;
            }

            img {
                width: 25px;
                height: auto;
                display: inline-block;
                vertical-align: -8px;
                margin-right: 10px;
            }
        }
    }
}

.marketplace {
    white-space: nowrap;
    overflow: hidden;
    background-color: none;
    width: 100%;
    color: var(--white);
    text-transform: uppercase;
    display: flex;
    background-color: var(--black);

    .availble {
        margin: auto;
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
    }

    .marketmarquee {
        display: flex !important;
        gap: 20px;
    }

    marquee {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 10px;

        .marqueeBody {
            -webkit-animation: marquee 15s linear infinite;
            animation: marquee 15s linear infinite;

            .text {
                display: inline-block;
                margin-right: 5.3em;
                font-size: 14px;
                line-height: 2.4;
                position: relative;
                letter-spacing: .6px;
            }

            img {
                width: 100px;
                height: auto;
                display: inline-block;
                vertical-align: -8px;
                margin-right: 10px;
            }
        }
    }
}

// Offer
.offerBanner {
    padding: 10px 0px 10px 0px;

    .offerbg {
        background: linear-gradient(127deg, #B3001B -16.14%, #931F1D 33.43%);
        border-radius: 3.125rem;
        padding: 2rem;

        .drdesc {
            margin: auto;

            h2 {
                font-size: 42px !important;
                color: var(--white);
            }

            p {
                color: var(--white);
                font-weight: 500;
            }

            .btntord {
                background: var(--yellow);
                border: none;
                border-radius: 20px;
                color: var(--black);
                font-size: 15px;
                width: 30%;
                margin: auto;
                padding: 6px 10px 6px 10px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }

        img {
            width: 100%;
        }
    }
}

.productCounter {
    .counter-block {
        display: flex;

        .counterLeft,
        .counterRight {
            padding: 11px 0px 10px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 35%;

            .microbiome-block {
                background: #fff;
                padding: 10px;
                border-radius: 10px;
                text-align: center;
                margin-bottom: 10px;

                .loader {
                    span {
                        background-image: linear-gradient(121.84deg, #ff7fba 13.8%, #fad6b4 69.15%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 600;
                        font-size: 100px;
                        line-height: 150px;
                        background-clip: text;
                    }
                }

                h4 {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 46px;
                    margin-bottom: 30px;
                }
            }
        }

        .productCenter {
            width: 30%;
            margin: auto;
            justify-content: center;
            display: flex;

            img {
                width: 50%;
            }
        }
    }
}

//
.mobileHeader {
    display: block;

    @include md {
        display: none;
    }

    .mobileMenuIcons {
        svg {
            font-size: 22px;
            color: var(--black);
        }
    }

    .mobileheaderLogo {
        width: 60px;

        img {
            width: 100%;
        }
    }

    .topCartCounter {
        .cartCounter {
            color: var(--orange);
        }
    }
}

.offcanvas-header {
    border-bottom: 1px solid var(--black);
}

.mobilenavbar {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobileHeaderSearch {
    width: 100%;

    form {
        width: 100%;
        margin-top: 5px;

        .searchBox {
            background-color: var(--background-color);
            display: flex;
            width: 100%;

            input {
                background-color: var(--background-color);
                border: none;
                border-radius: 0 6px 6px 0;
                bottom: 19px;
                box-shadow: none;
                color: #3e3e3e;
                display: block;
                display: inline;
                font-size: 14px;
                outline: 0;
                padding: 6px 30px 7px 0;
                text-indent: 12px;
                width: calc(100% - 25px);
            }

            .searchtbn {
                svg {
                    margin: auto !important;
                    margin-top: 5px !important;
                }
            }
        }
    }
}

.mvideosection {
    display: flex !important;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;
}

.mobilebestseller {
    display: flex !important;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;
    padding: 0px 5px;

    .bestseller-body {
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
        border-radius: 5px;
        margin: 0px 5px 0px 5px;
        margin-bottom: 15px !important;

        .card {
            width: 260px !important;

            .card-body {
                padding: 10px !important;

                .card-title {
                    font-family: Inter, sans-serif !important;
                    font-size: 1rem !important;
                    font-weight: 400;
                }

                .card-text {
                    .offersection {
                        .priceSection {
                            .price {
                                font-size: 15px;
                                color: var(--orange);
                                letter-spacing: 0.6px !important;
                            }

                            .cutprice {
                                font-size: 13px;
                                margin-top: 5px;
                                letter-spacing: 0.6px !important;
                            }
                        }
                    }

                    .ratingSection {
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                    }
                }
            }

            .buttonSection {
                button {
                    font-size: 12px !important;
                }
            }
        }
    }
}

.mobileshopby {
    display: flex !important;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;

    .shopByConcern {
        margin-bottom: 15px !important;

        img {
            width: 200px;
            height: 200px;
        }
    }
}

.mobileconcern {

    p,
    h2 {
        padding: 10px 0px !important;
    }
}

.mobileconcern {
    .counterLeft {
        width: 100% !important;

        h4 {
            font-size: 24px !important;
            margin-bottom: unset !important;
        }

        p {
            margin-bottom: unset !important;
        }

        .loader span {
            font-size: 60px !important;
            line-height: 80px !important;
        }
    }
}

.mobiledoctor {
    p {
        padding: unset !important;
    }

    .btns {
        button {
            width: 100%;
        }
    }
}

.mobileoffer {
    padding: 5px !important;

    .offerbg {
        padding: 1rem !important;

        .btns {
            button {
                width: 100% !important;
            }
        }
    }

    .offerbg .drdesc h2 {
        font-size: 25px !important;
        color: var(--white);
        margin-top: 10px;
    }

}

.mobileReview {
    display: flex !important;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;

    .card {
        min-width: 250px !important;
        margin-bottom: 15px !important;
    }
}

.mobilehomeblog {
    display: flex !important;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;

    .card {
        min-width: 250px !important;
        margin-bottom: 15px !important;
    }
}

.footer {
    flex: unset !important;
    margin-bottom: 10px !important;

    @include md {
        flex: 1 0 !important;
    }
}

.footerBottom {
    .col {
        flex: unset !important;

        address {
            font-size: 15px;
        }

        @include md {
            flex: 1 0 !important;
        }
    }

    img {
        display: none !important;

        @include md {
            display: block !important;
        }
    }
}

// Product
.shop-widget {
    background: var(--white);
    border: 1px solid var(--white);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
    position: sticky;
    top: 140px;
}

.shop-widget .shop-widget-title {
    border-bottom: 1px solid var(--text-gray);
    color: var(--black);
    padding-bottom: 12px;
    text-transform: uppercase;
}

.shop-widget .shop-widget-list li {
    align-items: center;
    color: var(--gray);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
}

.shop-widget .shop-widget-list li .shop-widget-content {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.shop-widget .shop-widget-list li .shop-widget-content input {
    cursor: pointer;
    height: 15px;
    margin-right: 12px;
    width: 15px;
}

.shop-widget .shop-widget-list li .shop-widget-content label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px !important;
    justify-content: center;
    text-transform: capitalize;
}

.shop-widget .shop-widget-list li .shop-widget-content label:hover {
    color: var(--orange);
    font-weight: 600;
}

.productTopBar {
    background: #fff;
    border-radius: 5px;
    padding: 10px 0 10px 0px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
    display: none;

    @include md {
        display: block !important;
    }

    h4 {
        color: var(--black);
        font-family: Inter, sans-serif !important;
        font-size: 1rem !important;
        font-weight: 400;
        padding: 0px 10px 0px 10px;
        margin-bottom: unset;
    }
}

.product-body {
    box-shadow: 0 2px 6px 0 #dae1ffd9, 1px 2px 4px 0 #c2bac9f2;
    border: none;
    margin-top: 20px;

    .card-text {
        .offersection {
            display: flex;
            justify-content: space-between;

            .priceSection {
                display: flex;
                gap: 20px;

                .price {
                    font-size: 20px;
                    color: var(--orange);
                    letter-spacing: .6px !important;
                }

                .cutprice {
                    font-size: 17px;
                    margin-top: 5px;
                    letter-spacing: .6px !important;
                }
            }

            .offer {
                span {
                    color: var(--light-green);
                }
            }
        }

        .ratingSection {
            display: flex;
            justify-content: space-between;

            .rating {
                color: var(--light-green);
            }
        }
    }

    .buttonSection {
        display: flex;
        justify-content: space-between;

        .addtocart {
            width: 48%;
            background-color: var(--white);
            color: var(--black);
            text-transform: uppercase;
            border: 1px solid var(--black);
            letter-spacing: .6px !important;
            font-size: 15px;

            &:hover {
                background-color: var(--black);
                color: var(--white);
            }
        }

        .buyitnow {
            width: 48%;
            background-color: var(--orange);
            color: var(--white);
            text-transform: uppercase;
            border: none;
            letter-spacing: .6px !important;
            font-size: 15px;

            &:hover {
                background-color: var(--white);
                color: var(--black);
                border: 1px solid var(--black);
            }
        }
    }
}

.ingdesc {
    p {
        letter-spacing: .6px !important;
        color: #696969 !important;
    }

    a {
        color: #0d6efd !important;
    }
}

.ingredient-body {
    box-shadow: 0 2px 6px 0 #dae1ffd9, 1px 2px 4px 0 #c2bac9f2;
    border: none;
    margin-top: 20px;

    .card-body {
        padding: 10px !important;

        .card-title {
            font-size: 1rem;
            color: #042b41;
        }
    }
}

.shopByConcernbody {
    gap: 10px;
    padding: 10px;

    .shopByConcern {
        width: 48%;
        margin: unset !important;
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
        border-radius: 10px;
        padding-left: unset !important;
        padding-right: unset !important;

        @include md {
            width: 16%;
        }

        img {
            width: 100%;
        }
    }
}

.testimonialbody {
    .col-md-3 {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .homereating {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.faqsbody {
    .accordion-item {
        border: none;
        margin-top: 10px;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
        border-radius: 10px;

        .accordion-header {
            border-radius: 5px;

            button {
                border-radius: 5px;
            }

            .accordion-button:not(.collapsed) {
                background: unset;
            }

            .accordion-button:focus {
                box-shadow: unset;
            }

        }

        .accordion-body {
            border-top: 1px solid var(--text-gray);
        }
    }
}

// product Details
.productDetailbody {
    .cimage {
        img {
            border-radius: 10px;
        }
    }

    .carousel {
        .thumbs-wrapper {
            margin: 10px 0px !important;
        }
    }

    .carousel .thumb {
        border-radius: 15px !important;

        @include md {
            width: 120px !important;
        }
    }

    .carousel .thumb.selected,
    .carousel .thumb:hover {
        border: 1px solid var(--green-border);
        border-radius: 10px !important;
        padding: unset !important;
    }

    .detailBody {
        h1 {
            font-size: 21px !important;
        }

        small {
            font-size: 21px;

            svg {
                color: #ff9f00 !important;
            }
        }

        .benefits_span,
        .product__text_description {
            color: dimgray !important;
            font-family: Inter, sans-serif !important;
            font-size: 15px !important;
            font-style: normal;
            font-weight: 500 !important;
            letter-spacing: 0.6px !important;
        }

        hr {
            border-top: 1px solid #b5c1bd;
            color: #dfe2e1;
        }

        .benefitDescicons {
            flex-wrap: wrap;
            row-gap: 0.55rem;
            display: flex;

            .descIcons {
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                column-gap: 0.5em;
                padding-right: 0.5em;
                padding-top: 5px;

                img {
                    max-width: 3rem;
                    padding: 0px;
                    background: #000;
                    border-radius: 50px;
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: .6px;
                }
            }
        }
    }

    .varientSesction {
        display: flex;
        gap: 10px;

        .varient {
            input {
                display: none;
            }

            input[type=radio]:checked+.varientBody {
                display: inline-block !important;
                background: #e1f2e3 !important;
                border: 1px solid #64ba7e !important;
                color: #000 !important;
                border-radius: 10px;
                cursor: pointer;
            }

            .varientBody {
                display: inline-block !important;
                background: transparent;
                border: 1px solid #e8e8e8 !important;
                color: #000 !important;
                border-radius: 10px;
                cursor: pointer;

                .varientDetail {
                    padding: 5px;
                    text-align: center;

                    @include md {
                        padding: 10px;
                    }

                    .packType {
                        color: #065c2f;
                        font-weight: 600;
                        font-size: 12px;
                        text-transform: uppercase;
                        letter-spacing: 0;
                        margin-bottom: 5px !important;
                        letter-spacing: 0.6px;

                        @include md {
                            font-size: 16px;
                            margin-bottom: 10px !important;
                        }
                    }

                    .packSave {
                        background: #095933;
                        color: #fff;
                        padding: 5px;
                        font-size: 9px;
                        font-weight: 600;
                        letter-spacing: 0;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        border-radius: 5px;
                        letter-spacing: 0.6px;
                        margin-bottom: unset !important;

                        @include md {
                            font-size: 12px;
                        }
                    }

                    .packPrice {
                        color: #095933;
                        margin: 5px;
                        letter-spacing: 0.6px !important;
                        font-size: 15px;
                        font-weight: 700;
                        margin-bottom: -4px;

                        @include md {
                            font-size: 21px;
                            margin: 10px;
                            margin-bottom: unset !important;
                        }
                    }

                    .cutprice {
                        color: #000;
                        font-weight: 600;
                        font-size: 10px;
                        letter-spacing: 0.6px;

                        @include md {
                            font-size: 15px;
                        }
                    }

                    .totaltablet {
                        margin-top: 5px;
                        font-size: 9px;
                        letter-spacing: 0.5px !important;

                        @include md {
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }

                    .pertablet {
                        margin: unset;
                        font-size: 8px;
                        margin-top: 10px;
                        letter-spacing: 0.5px !important;

                        @include md {
                            font-size: 10px;
                        }
                    }
                }

                .packname {
                    background-color: #51ba69;
                    color: #fff;
                    padding: 8px 5px;
                    border-radius: 0 0 10px 10px;
                    font-size: 10px;
                    font-weight: 600;
                    margin-bottom: -16px;
                    text-align: center;
                    margin: -1px;
                    letter-spacing: 0.5px !important;

                    @include md {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .productPrice {
        margin-top: 10px;

        .pro-price {
            display: flex;
            gap: 10px;

            .price {
                display: flex;
                gap: 10px;

                .new-price {
                    color: var(--orange);
                    font-size: 26px;
                    font-weight: 600;
                }

                .cutprice {
                    color: #666;
                    margin: auto;
                }

                .p-discount {
                    margin: auto;
                    padding: .1rem 1.3rem;
                    word-break: break-word;
                    background-color: #ff9f00 !important;
                    font-size: .8rem;
                    color: #000 !important;
                    font-weight: 700 !important;
                    border-radius: 10px;
                }
            }
        }
    }

    .buttonSection {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .addtocart {
            width: 48%;
            background-color: var(--white);
            color: var(--black);
            text-transform: uppercase;
            border: 1px solid var(--black);
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--black);
                color: var(--white);
            }
        }

        .buyitnow {
            width: 48%;
            background-color: var(--orange);
            color: var(--white);
            text-transform: uppercase;
            border: none;
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--white);
                color: var(--black);
                border: 1px solid var(--black);
            }
        }
    }
}

// 
.loginCarousel {
    margin: auto;

    .img {
        width: 100px;
        margin: auto;

        img {
            width: 100%;
        }
    }

    h5 {
        margin: 30px;
    }

    p {
        padding: 0 50px;
        font-size: 15px;
        color: #131216c2;
        letter-spacing: .6px;
        margin-bottom: 50px;
    }
}

.loginslider {
    border-right: 1px solid #f5f5f5;
}

.loginContent {
    text-align: center;

    h4 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .getaccess {
        color: #616161;
        font-size: 12px;
        font-weight: 400;
    }

    form {
        margin-top: 50px;

        .btnlogin {
            width: 100%;
            background: var(--orange);
            text-transform: uppercase;
            border: none;
            letter-spacing: 1px;
        }
    }

    .loginBottom {
        margin-top: 30px;

        .policy {
            p {
                font-size: 14px;
                color: #131216c2;
                margin-bottom: 3px !important;
                letter-spacing: 0.6px;

                a {
                    text-decoration: underline;
                }
            }
        }

        .needhelp {
            margin-top: 20px;

            a {
                color: var(--orange);
            }
        }
    }

    .otpBottom {
        margin-top: 20px;

        .policy {
            p {
                font-size: 14px;
                color: #131216c2;
                margin-bottom: 3px !important;
                letter-spacing: 0.6px;

                a {
                    text-decoration: underline;
                }
            }
        }

        .needhelp {
            margin-top: 20px;

            a {
                color: var(--orange);
            }
        }
    }
}

.otpBody {
    form {
        margin-top: 30px !important;

        .changeBtn {
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            font-size: 13px;

            span {
                letter-spacing: 0.6px;
                color: var(--text-color);
                cursor: pointer;
            }
        }
    }
}

.otpform {
    display: flex;
    gap: 10px;

    input {
        width: 25% !important;
        text-align: center;
    }
}

.mimg {
    border: 1px solid #dfe2e1;
    margin: 0px 5px 0px 5px;
    border-radius: 0.5rem;
    margin-bottom: 20px;

    img {
        width: 100%;
        vertical-align: unset !important;
    }
}

.videobody {
    cursor: pointer;
}

.videoModalBody {
    .container {
        padding-left: unset !important;
        padding-right: unset !important;
    }

    video {
        width: 100% !important;

        @include md {
            width: 380px !important;
        }
    }
}

.popupproductDetails {
    .cimage {
        img {
            width: 100% !important;
            height: 280px;
        }
    }

    .pdetail {
        h1 {
            font-size: 21px !important;
            margin-top: 5px;
            margin-bottom: unset;
        }

        .text-warning {
            font-size: 12px;

            svg {
                color: #ff9f00 !important;
            }
        }

        .totalrev {
            font-size: 12px;
        }

        p {
            font-size: 15px !important;
            margin-bottom: unset !important;

            .benefits_span {
                font-size: 12px !important;
            }
        }

        .product__text_description {
            margin-top: 5px;
            font-size: 13px !important;
            color: dimgray !important;
            font-family: Inter, sans-serif !important;
            font-style: normal;
            font-weight: 500 !important;
            letter-spacing: 0.6px !important;
        }

        .productPrice {
            .pro-price {
                display: flex;
                gap: 10px;

                .price {
                    display: flex;
                    gap: 10px;

                    .new-price {
                        color: var(--orange);
                        font-size: 20px;
                        font-weight: 600;
                    }

                    .cutprice {
                        color: #666;
                        margin: auto;
                        font-size: 15px;
                    }

                    .p-discount {
                        margin: auto;
                        padding: .1rem 1.3rem;
                        word-break: break-word;
                        background-color: #ff9f00 !important;
                        font-size: .6rem;
                        color: #000 !important;
                        font-weight: 700 !important;
                        border-radius: 10px;
                    }
                }

            }
        }

        .varientSesction {
            display: flex;
            gap: 10px;

            .varient {
                input {
                    display: none;
                }

                input[type=radio]:checked+.varientBody {
                    display: inline-block !important;
                    background: #e1f2e3 !important;
                    border: 1px solid #64ba7e !important;
                    color: #000 !important;
                    border-radius: 10px;
                    cursor: pointer;
                }

                .varientBody {
                    display: inline-block !important;
                    background: transparent;
                    border: 1px solid #e8e8e8 !important;
                    color: #000 !important;
                    border-radius: 10px;
                    cursor: pointer;

                    .varientDetail {
                        padding: 5px;
                        text-align: center;

                        .packType {
                            color: #065c2f;
                            font-weight: 600;
                            font-size: 12px !important;
                            text-transform: uppercase;
                            letter-spacing: 0;
                            margin-bottom: 5px !important;
                            letter-spacing: 0.6px;
                        }

                        .packSave {
                            background: #095933;
                            color: #fff;
                            padding: 0px !important;
                            font-size: 9px !important;
                            font-weight: 600;
                            letter-spacing: 0;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            border-radius: 5px;
                            letter-spacing: 0.6px;
                            margin-bottom: unset !important;
                        }

                        .packPrice {
                            color: #095933;
                            margin: 5px;
                            letter-spacing: 0.6px !important;
                            font-size: 15px;
                            font-weight: 700;
                            margin-bottom: -4px;
                        }

                        .cutprice {
                            color: #000;
                            font-weight: 600;
                            font-size: 10px;
                            letter-spacing: 0.6px;
                        }

                        .totaltablet {
                            margin-top: 5px;
                            font-size: 8px;
                            letter-spacing: 0.5px !important;

                            @include md {
                                font-size: 9px;
                            }
                        }

                        .pertablet {
                            margin: unset;
                            font-size: 8px;
                            margin-top: 10px;
                            letter-spacing: 0.5px !important;
                        }
                    }

                    .packname {
                        background-color: #51ba69;
                        color: #fff;
                        padding: 8px 5px;
                        border-radius: 0 0 10px 10px;
                        font-size: 8px !important;
                        font-weight: 600;
                        margin-bottom: -16px;
                        text-align: center;
                        margin: -1px;
                        letter-spacing: 0.5px !important;

                        @include md {
                            font-size: 10px !important;
                        }
                    }
                }
            }
        }
    }

    .buttonSection {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .addtocart {
            width: 48%;
            background-color: var(--white);
            color: var(--black);
            text-transform: uppercase;
            border: 1px solid var(--black);
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--black);
                color: var(--white);
            }
        }

        .buyitnow {
            width: 48%;
            background-color: var(--orange);
            color: var(--white);
            text-transform: uppercase;
            border: none;
            letter-spacing: .6px !important;

            &:hover {
                background-color: var(--white);
                color: var(--black);
                border: 1px solid var(--black);
            }
        }
    }
}

.modalheader {
    padding: unset;
    font-size: 10px;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 0px;
}

.section-tab {
    padding: 0px 0;
    position: sticky;
    top: 96px;
    z-index: 11;

    @include md {
        top: 130px;
    }
}

@media (max-width: 768px) {
    .section-tab {
        overflow: auto;
        width: 100%;
    }
}

.bg-tab {
    background: var(--black);
}

.all-tab ul {
    display: flex;
    list-style: none;
    left: 0;
    margin: 0;
    padding-left: 0rem;
}

.all-tab ul li {
    padding: 10px;
    white-space: nowrap;
    word-break: initial;
}

.all-tab ul li a {
    color: #fff;
    line-height: 1.8;
    font-size: 16px;
    position: relative;
}

.all-tab ul li a::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    background-color: #FFCC00;
    top: 25px;
    left: 0;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #FFCC00;
}

.section-all .key-benefit .use-card {
    background: #e6f2eb;
    padding: 10px 25px;
    border: #f3f3f3 1px solid;
    height: 270px;
    border-radius: 3px;
}

.section-all .key-benefit .use-card img {
    width: 75%;
    display: block;
    border-radius: 100%;
    overflow: hidden;
    margin: auto;
    border: #243726 3px solid;
}

.section-all .key-benefit .use-card p {
    font-size: 16px;
    color: #222;
    line-height: 1.3;
    margin: 0;
    padding-top: 10px;
}

.t-tittle h2 {
    font-size: 25px !important;
    font-weight: 600;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .section-all .key-benefit .use-card {
        height: 200px;
        margin-top: 10px;
        padding: 10px 5px;
    }
}

.videoTitle {
    letter-spacing: 2.5px !important;
    font-size: 25px !important;
}

.videoContnt {
    letter-spacing: 0.6px;
}

.section-all .key-ingredient .ing-box {
    border-radius: 20px;
    background: #e1e5ee;
    padding: 30px;
    border: #243726 2px solid;
}

.section-all .key-ingredient .ing-box img {
    border-radius: 2rem;
    width: 100%;
}

.section-all .key-ingredient .ing-box-text p {
    font-size: 14px;
    color: #222;
    line-height: 1.3;
    margin: 0;
    padding-top: 5px;
    letter-spacing: 0.6px;
}

@media (max-width: 768px) {
    .section-all .key-ingredient .ing-box-text h4 {
        font-size: 18px;
        font-weight: 600;
        margin-top: 5px !important;
    }
}

.steps .step {
    padding: 20px 0 15px;
    border-bottom: 1px solid #000;
}

.steps .step h4 {
    font-size: 26px;
    font-weight: 600;
    color: #59702f;
}

.table>tbody {
    vertical-align: inherit;
    border: 1px solid #b1b1b1;
}

.moretext {
    ul {
        margin-left: 40px;
    }
}

.faqbody {
    .accordion-item {
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
        border-radius: 5px;
        margin: 0px 5px 0px 5px;
        margin-top: 10px;
    }
}

.popupCart {
    svg {
        color: var(--orange);
    }

    .totalitem {
        color: var(--orange);
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .offcanvas-body {
        padding: unset !important;
    }

    .cartItems {
        height: 100%;
        max-height: calc(100vh - 120px);
        overflow-y: auto;

        ul {
            .cartList {
                border-bottom: 1px solid var(--orange);
                list-style: none;

                .cartBody {
                    align-items: center !important;
                    align-items: flex-start !important;
                    display: flex;
                    justify-content: center;
                    padding: 10px 10px;

                    .cart-media {
                        margin-right: 25px;
                        position: relative;

                        img {
                            width: 100%;
                            border-radius: 8px;
                        }
                    }

                    .cart-info-group {
                        width: 100%;

                        .cart-info {
                            margin-bottom: 13px;

                            h6 {
                                color: var(--black);
                                font-size: 12px;
                                font-weight: 600;
                                margin-top: 4px;
                                text-transform: capitalize;
                            }

                            p {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--orange);
                            }
                        }

                        .cart-action-group {
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            justify-content: space-between;

                            .product-action {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                input {
                                    background: hsla(0, 0%, 50%, 0);
                                    border-radius: 5px;
                                    color: var(--black);
                                    font-size: 14px;
                                    height: 30px;
                                    margin: 0 10px;
                                    text-align: center;
                                    width: 45px;
                                }

                                .action-minus {
                                    background: var(--orange);
                                    border-radius: 6px;
                                    display: inline-block;
                                    font-size: 14px;
                                    height: 30px;
                                    text-shadow: var(--black);
                                    width: 30px;

                                    svg {
                                        color: var(--white);
                                        margin: auto;
                                        margin-top: -5px;
                                    }
                                }

                                .action-plus {
                                    background: var(--orange);
                                    border-radius: 6px;
                                    display: inline-block;
                                    font-size: 14px;
                                    height: 30px;
                                    text-shadow: var(--black);
                                    width: 30px;

                                    svg {
                                        color: var(--white);
                                        margin: auto;
                                        margin-top: -5px;
                                    }
                                }
                            }

                            h6 {
                                color: var(--black);
                                font-weight: 500;
                                margin-top: 10px;
                                margin-left: 10px;
                            }
                        }
                    }

                    .cartTrash {
                        .cart-delete {
                            background: hsla(0, 0%, 100%, .9);
                            border-radius: 5px;
                            color: var(--orange);
                            display: inline-block;
                            font-size: 18px;
                            width: 25px;
                        }
                    }
                }
            }
        }

        .cart-footer {
            box-shadow: 0 -3px 7px 0 rgba(0, 0, 0, 0.08);
            padding: 6px 15px;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 0px;

            .cart-checkout-btn {
                align-items: center;
                background: var(--black);
                border: 1px solid var(--orange);
                border-radius: 8px;
                display: flex;
                justify-content: center;
                padding: 10px 0;

                span {
                    color: var(--white);
                }
            }
        }
    }
}

.steper {
    margin-top: 10px;
    background: var(--white);
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);
}

.account-card {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 0 20px 10px 20px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px 2px 4px 0px rgba(194, 186, 201, 0.95);

    .account-title {
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 5px 0;
        position: relative;

        h4 {
            font-size: 1.2rem !important;
        }
    }

    .account-title:before {
        background: var(--orange);
        bottom: -2px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100px;
    }

    .account-content {
        label {
            color: var(--black);
            font-size: 18px;
        }

        .btncheckcont {
            width: 100%;
            background-color: var(--black);
            color: var(--white);
            text-align: center;
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid var(--orange);
            margin-top: 10px;
        }
    }
}

.secureLogin {
    float: right;

    h4 {
        color: var(--orange);
    }

    ul {
        li {
            font-size: 15px;
            list-style: none;

            span {
                margin-left: 5px;
            }
        }
    }
}

.deliverynames {
    display: flex;
    justify-content: space-between;

    @include md {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .deliverynumber {
        margin-left: 10px;
    }

    .deliveryBtn {
        button {
            border: none;
            padding: 2px 3px 2px 8px;
            border-radius: 5px;
        }

        .btnEdit {
            margin-right: 10px;
            background: var(--black);
            color: var(--white);
        }

        .btnRemove {
            background: var(--orange);
            color: var(--white);
        }
    }
}

.adeliveryaddressbtn {
    button {
        border: none;
        border-radius: 5px;
        background: var(--orange);
        padding: 5px 10px;
        color: var(--white);
        margin-top: 10px;
        font-size: 15px;
        letter-spacing: .6px !important;
    }
}

.ddeliveryBottom {
    border-bottom: 1px dashed var(--text-gray);
}

.account-content {
    .billingsAddress {
        display: flex;
        gap: 5px;

        svg {
            margin-top: 3px;
            margin-right: 5px;
        }

        .locationIcon {
            font-size: 20px;
            margin-left: -4px;
        }
    }
}

.card-summary {
    background: var(--white);
    border-radius: 8px;
    margin-bottom: 10px;

    .card-summary-details {
        padding: 10px 10px;

        .cartImg {
            img {
                width: 100%;
            }
        }
    }
}

.changeaddress {
    color: #0d6efd;
}

.orderSummaryDetailsPrice {
    p {
        margin-bottom: unset;
        color: dimgray;
        font-family: Inter, sans-serif !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400 !important;
        letter-spacing: .6px !important;
        line-height: 1.74 !important;
        text-align: justify;
        visibility: visible;

    }

    .action-minus,
    .action-plus {
        background: var(--black);
        border: none;
        border-radius: 5px;
        color: var(--white);
        padding: 3px 2px 3px 8px;
    }

    .cart-action-group .product-action input {
        background: #f5f5f5;
        border: none;
        border-radius: 5px;
        color: #a4957d;
        font-size: 14px;
        height: 30px;
        width: 45px;
        margin: 0px 10px;
        text-align: center;
    }

    h4 {
        display: flex;
        font-size: 1rem;

        button {
            margin-top: -15px;
        }
    }

    .cart-action-group {
        justify-content: start;
    }

    .btntrash {
        float: right;
        border: none;
        color: red;
        padding: 5px;

    }
}

.price-summary {
    .price-summary-details {
        display: flex;
        justify-content: space-between;

        .priceCharge {
            color: var(--green);
        }

        span {
            margin-top: 5px;
        }
    }

    .total-amount-details {
        border-top: 1px dashed var(--text-gray);
        margin-top: 20px;

        span {
            margin-top: 10px;
        }
    }

    .price-summary-payment {
        width: 100%;
        background: var(--black);
        padding: 5px 5px 5px;
        margin-top: 10px;
        border-radius: 5px;
        text-align: center;

        button {
            color: var(--white);
            background: none;
            border: none;
        }
    }
}